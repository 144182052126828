<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div class="row">

				<div class="col-md-12 mt-3">


					<div>
						
						<div>
						<h3>New Apartment</h3>
					</div>


					<div class="shadow rounded p-3">


						<form @submit.prevent="new_apartment()" enctype="multipart/form-data">
							<div class="row">
								<div class="col-md-12 mt-3">
									<label>Category</label>
									<select class="form-select" v-model="category">
										<option v-for="c in categories" :value="c.id">{{c.name}}</option>
									</select>
								</div>


								<div class="col-md-4 mt-3">
									<label>Name of apartment</label>
									<input type="text" class="form-control" placeholder="Enter name of apartment" v-model="name">
								</div>


								<div class="col-md-4 mt-3">
									<label>Address</label>
									<input type="text" class="form-control" placeholder="Enter address of apartment" v-model="address">
								</div>

								<div class="col-md-4 mt-3">
									<label>County</label>
									<input type="text" class="form-control" placeholder="Enter county of apartment" v-model="county">
								</div>

								<div class="col-md-4 mt-3">
									<label>Daily price</label>
									<input type="number" min="1" class="form-control" placeholder="Enter price" v-model="price">
								</div>

								<div class="col-md-4 mt-3">
									<label>Weekly price</label>
									<input type="number" min="0" class="form-control" placeholder="Enter price" v-model="weekly_price">
								</div>

								<div class="col-md-4 mt-3">
									<label>Monthly price</label>
									<input type="number" min="0" class="form-control" placeholder="Enter price" v-model="monthly_price">
								</div>


								<div class="col-md-4 mt-3">
									<label>Feature image</label>
									<input type="file" class="form-control" @change="get_image">
								</div>

								<div class="col-md-4 mt-3">
									<label>Gallery</label>
									<input type="file" ref="gallery" class="form-control" @change="get_gallery" multiple="multiple">
								</div>

								<div class="col-md-4 mt-3">
									<label>Rules</label>
									<input type="text" class="form-control" placeholder="Enter house rules" v-model="rules">
								</div>


								<div class="col-md-4 mt-3">
									<label>Kitchens</label>
									<input type="number" min="0" class="form-control" placeholder="Number of kitchens" v-model="kitchens">
								</div>


								<div class="col-md-4 mt-3">
									<label>Bathrooms</label>
									<input type="number" min="0" class="form-control" placeholder="Number of bathrooms" v-model="bathrooms">
								</div>

								<div class="col-md-4 mt-3">
									<label>Bedrooms</label>
									<input type="number" min="0" class="form-control" placeholder="Number of bedrooms" v-model="bedrooms">
								</div>


								<div class="col-md-4 mt-3">
									<label>Amenities</label>
									<div>
									  <input type="text" class="form-control" placeholder="Amenity name"  v-model="amenity">
									  
									</div>
								</div>

								<div class="col-md-4 mt-3">
									<label></label>

									<div class="input-group mb-3">
									  <input type="text" class="form-control" placeholder="Amenity price" aria-label="Recipient's username" aria-describedby="button-addon2" v-model="amenity_price">
									  <button @click="add_amenity()" class="btn btn-outline-secondary" type="button" id="button-addon2">Add <i class="bi bi-plus"></i> </button>
									</div>
									
								</div>

								<div class="col-md-12 mt-3">
								<div>
									<button style="border:solid 1px #f5f5f5; margin-right: 5px;" v-for="a in amenities" class="btn">{{a.name}} </button>
								</div>

								</div>


								<div class="col-md-12 mt-3">
									<button class="btn btn-1" :disabled="disabled">{{text}}</button>
								</div>


							</div>

						</form>
						

					</div>

					</div>
					

				</div>


				
			</div>


		

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	import Swal from 'sweetalert2'
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
				disabled : false,
				text : 'Submit',
				categories : [],
				category : '',
				name : '',
				address : '',
				image : '',
				gallery : [],
				rules : '',
				kitchens : '',
				bathrooms : '',
				bedrooms : '',
				county : '',
				price : '',
				form_data : new FormData(),
				amenity : '',
				amenities : [],
				amenity_price : '',
				weekly_price : '',
				monthly_price : ''

				
			}
		},

		methods : {

			add_amenity(){
				if(!this.amenity || !this.amenity_price ){
					return 
				}
				let item = [{ 'name' : this.amenity, 'price' : this.amenity_price }]
				this.amenities = this.amenities.concat(item)
				this.amenity = ''
				this.amenity_price = ''
			},

			get_gallery(event){
				for (var i = 0; i < this.$refs.gallery.files.length; i++ ){
		        let file = this.$refs.gallery.files[i];
		       
		        this.form_data.append('gallery[]',file)
		    }
		    
				
			},

				get_image(event){
				this.image = event.target.files[0]
				
			},

			async new_apartment(){

				if(!this.category){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Category is required",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}



				if(!this.address){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Address is required",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}



				if(!this.image){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Image is required",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}

				if(!this.kitchens){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Kitchens is required",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}

				if(!this.bathrooms){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Bathrooms is required",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}


				if(!this.bedrooms){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Bedrooms is required",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}

				if(!this.county){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "County is required",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}

				if(!this.price){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Price is required",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}


					 const config = {
      header : {
        'Content-Type' : 'multipart/form-data'
      }
    }

    		
    		this.form_data.append('user_id',this.$store.state.id)
    		this.form_data.append('category',this.category)
    		this.form_data.append('name',this.name)
    		this.form_data.append('address',this.address)
    		this.form_data.append('county',this.county)
    		this.form_data.append('price',this.price)
    		this.form_data.append('image',this.image)
    		this.form_data.append('description',this.rules)
    		this.form_data.append('kitchens',this.kitchens)
    		this.form_data.append('bathrooms',this.bathrooms)
    		this.form_data.append('bedrooms',this.bedrooms)
    		this.form_data.append('amenities', JSON.stringify(this.amenities))
    		this.form_data.append('weekly_price', JSON.stringify(this.weekly_price))
    		this.form_data.append('monthly_price', JSON.stringify(this.monthly_price))
    		
    		this.text = 'Please wait...'
    		this.disabled = true

				const res = await axios.post(this.$store.state.url+'api/new-apartment',
					this.form_data
				,config).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				

				if(res=='success'){
					Swal.fire({
					  position: "top-center",
					  icon: "success",
					  title: "Apartment added successfully",
					  showConfirmButton: false,
					  timer: 1500
					});
				}else{

					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Something went wrong!",
					  showConfirmButton: false,
					  timer: 1500
					});

				}
				
				this.category = ''
				this.name = ''
				this.location = ''
				this.price = ''
				this.address = ''
				this.category = ''
				this.county = ''
				this.price = ''
				this.image = ''
				this.gallery = ''
				this.bathrooms = ''
				this.bedrooms = ''
				this.kitchens = ''
				this.rules = ''
				this.amenities = ''

				this.text = 'Submit'
    		this.disabled = false
				
				
			},

			async get_categories(){
				const res = await axios.get(this.$store.state.url+'api/list-categories').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.categories = res 
			}
		
		},

		created(){
		this.get_categories()	
		}

	}

</script>