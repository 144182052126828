<template>


<section>
	

	<div class="container">

		<div class="row">

			<div class="col-md-6" style="background-image: url('/assets/images/portrait-interracial-couple-home (1).jpg'); background-size: cover; background-position: center; "></div>

			<div class="col-md-5">
				
				<div style="padding-top: 50px; padding-bottom: 50px; ">

					<div style="padding-bottom: 0px; text-align: center;">
						<img src="/assets/images/new-logo.png" style="height: 70px;">
						<h3><b>Welcome!</b></h3>
						<h4>Create Your Account</h4>
					</div>
					
					<form @submit.prevent="register()">

						<div class="row">

							<div class="col-md-6">
								<div class="mt-3"> 
							<label>First name <span class="text-danger">*</span> </label>
							<input class="form-control" placeholder="Enter first name" type="text" v-model="first_name">
						</div>
							</div>


							<div class="col-md-6">
							
						<div class="mt-3"> 
							<label>Last name <span class="text-danger">*</span> </label>
							<input class="form-control" type="text" placeholder="Enter last name" v-model="last_name">
						</div>	
							</div>



							<div class="col-md-6">
							
						<div class="mt-3"> 
							<label>Email <span class="text-danger">*</span> </label>
							<input class="form-control" type="email" placeholder="Enter email address" v-model="email">
						</div>	
							</div>


								<div class="col-md-6">
							
						<div class="mt-3"> 
							<label>Phone <span class="text-danger">*</span> </label>
							<input class="form-control" type="text" placeholder="Enter phone number" v-model="phone">
						</div>	
							</div>


							<div class="col-md-6">
							
						<div class="mt-3"> 
							<label>County </label>
							<input class="form-control" type="text" placeholder="Enter your county" v-model="county">
						</div>	
							</div>


							<div class="col-md-6">
							
						<div class="mt-3"> 
							<label>Physical address</label>
							<input class="form-control" type="text" placeholder="Enter address" v-model="address">
						</div>	
							</div>



							<div class="col-md-12">
							
						<div class="mt-3"> 
							<label>Password</label>
							<input class="form-control" type="password" placeholder="Enter password" v-model="password">
						</div>	
							</div>



						<div class="mt-3 col-md-12">

							<div class="mb-3">
								<input type="checkbox" required> <button style="background-color:transparent; border:none; " class="p-0" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">I agree to <span class="text-danger">terms & condtions </span> </button>
							</div>

							<button class="btn btn-1 w-100" :disabled="disabled">{{text}}</button>
						</div>


							
						</div>


						
										
						



						<div class="mt-3">
							<router-link class="btn p-0" to="/">Already have an account? <span class="text-primary">Login</span> </router-link>
						</div>

					</form>

				</div>

			</div>
			
			
		</div>
		
	</div>


</section>



<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Terms & Conditions</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        
        <embed src="/assets/docs/Agents Terms and Conditions.pdf" style="width: 100%;"></embed>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>




</template>




<script>
	import axios from 'axios'
	import Swal from 'sweetalert2'

	export default{
		name  : 'login',
		data(){
			return{
				first_name : '',
				last_name : '',
				phone : '',
				county : '',
				address : '',
				email : '',
				password : '',
				disabled : false,
				text : 'Register'
			}
		},

		methods : {
			async register(){
				if(!this.email){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Email is required",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}


				if(!this.first_name){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "First name is required",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}


				if(!this.last_name){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Last name is required",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}

				if(!this.phone){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Phone is required",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}

				if(!this.county){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "County is required",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}

				if(!this.address){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Address is required",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}


				if(!this.password){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Password is required",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}

				this.disabled = true
				this.text = 'Please wait...'


				const res = await axios.post(this.$store.state.url+'api/agent-sign-up',{
					email : this.email,
					password : this.password,
					first_name : this.first_name,
					last_name : this.last_name,
					phone : this.phone,
					county : this.county,
					address : this.address
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				if(res=='success'){

					Swal.fire({
					  position: "top-center",
					  icon: "success",
					  title: "Account created successfully",
					  showConfirmButton: false,
					  timer: 1500
					});

				}else{

					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: res,
					  showConfirmButton: false,
					  timer: 1500
					});

				}

				this.disabled = false
				this.text = 'Register'
				

			}
		}
	}

</script>

