import { createRouter, createWebHistory } from 'vue-router'
import Login from '../components/Login'
import Register from '../components/Register'
import Dashboard from '../components/Dashboard'
import NewApartment from '../components/NewApartment'
import MyApartments from '../components/MyApartments'
import ApartmentDetails from '../components/ApartmentDetails'
import Orders from '../components/Orders'
import OrderDetails from '../components/OrderDetails'
import Settings from '../components/Settings'
import ForgotPassword from '../components/ForgotPassword'
import BlockDates from '../components/BlockDates'



const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },

   {
    path: '/register',
    name: 'register',
    component: Register
  },

    {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard
  },

   {
    path: '/new-apartment',
    name: 'new-apartment',
    component: NewApartment
  },

    {
    path: '/my-apartments',
    name: 'my-apartments',
    component: MyApartments
  },

  {
    path: '/apartment-details/:id',
    name: 'apartment-details',
    component: ApartmentDetails
  },

   {
    path: '/orders',
    name: 'orders',
    component: Orders
  },

    {
    path: '/order-details/:id',
    name: 'order-details',
    component: OrderDetails
  },


    {
    path: '/settings',
    name: 'settings',
    component: Settings
  },

    {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword
  },



    {
    path: '/block-dates',
    name: 'block-dates',
    component: BlockDates
  },




  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
