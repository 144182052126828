<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div>
				<h6 class="color-3">Logged in as <b> {{this.$store.state.first_name}} </b></h6>
			</div>


			<div class="row">

				<div class="col-md-6">
					<div>
				<h3 class="color-3">Welcome Back!</h3>
			</div>
				</div>


				<div class="col-md-6" style="text-align: right;">


					
				</div>
				
			</div>


			<div class="row">

				<div class="col-md-3 mt-3">

					<div class="shadow rounded p-2 card-design-2">
					
					<div class="row">

						<div class="col-md-8 col-8">
							<h3 class="color-1"> {{apartments.length}} </h3>
							<p>Apartments</p>
						</div>

						<div class="col-md-4 col-4" style="text-align:right;">
						<h1> <i class="bi bi-grid color-1"></i> </h1>
						</div>
						
					</div>	


					<div class="border-top">
						<router-link class="btn" to="/my-apartments"> <i class="bi bi-eye color-1"></i> View apartments </router-link>
					</div>


					</div>
					

				</div>







				<div class="col-md-3 mt-3">

					<div class="shadow rounded p-2 card-design-2">
					
					<div class="row">

						<div class="col-md-8 col-8">
							<h3 class="color-1"> {{orders.length}} </h3>
							<p>Orders</p>
						</div>

						<div class="col-md-4 col-4" style="text-align:right;">
						<h1> <i class="bi bi-layers color-1"></i> </h1>
						</div>
						
					</div>	


					<div class="border-top">
						<router-link class="btn" to="/orders"> <i class="bi bi-eye color-1"></i> View orders </router-link>
					</div>


					</div>
					

				</div>



					<div class="col-md-3 mt-3">

					<div class="shadow rounded p-2 card-design-2">
					
					<div class="row">

						<div class="col-md-8 col-8">
							<h3 class="color-1"> + </h3>
							<p>Add Apartment</p>
						</div>

						<div class="col-md-4 col-4" style="text-align:right;">
						<h1> <i class="bi bi-collection color-1"></i> </h1>
						</div>
						
					</div>	


					<div class="border-top">
						<router-link class="btn" to="/new-apartment"> <i class="bi bi-eye color-1"></i> New apartment </router-link>
					</div>


					</div>
					

				</div>





					<div class="col-md-3 mt-3">

					<div class="shadow rounded p-2 card-design-2">
					
					<div class="row">

						<div class="col-md-8 col-8">
							<h3 class="color-1"> + </h3>
							<p>Settings</p>
						</div>

						<div class="col-md-4 col-4" style="text-align:right;">
						<h1> <i class="bi bi-gear color-1"></i> </h1>
						</div>
						
					</div>	


					<div class="border-top">
						<router-link class="btn" to="/settings"> <i class="bi bi-eye color-1"></i> settings </router-link>
					</div>


					</div>
					

				</div>

				
			</div>









						
						<div style="padding-top: 30px;">
						<h3>Orders</h3>
					</div>


					<div class="shadow rounded p-3">					
					
					<div class="table-responsive">
						<table class="table">

							<thead>
								<tr>
									<th> Order# </th>
									<th> Apartment </th>
									<th> Customer </th>
									<th> Email </th>
									<th> Phone </th>
									<th> Action </th>
								</tr>
							</thead>

							<tbody>
								
								<tr v-for="o in orders">
									<td> {{o.order_number}} </td>
									<td> {{o.apartment_name}} </td>
									<td> {{o.first_name}} {{o.last_name}} </td>
									<td> {{o.email}} </td>
									<td> {{o.phone}} </td>
									<td> <router-link :to=" { name : 'order-details', params : { id : o.id } } " class="btn text-success"> <i class="bi bi-three-dots"></i> </router-link> </td>
								</tr>

							</tbody>


							<tbody>
								
								

							</tbody>
							
						</table>
						
					</div>


					</div>







		

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
				apartments : [],
				orders : [],
			}
		},

		methods : {

			async get_orders(){
				const res = await axios.get(this.$store.state.url+'api/my-orders/'+this.$store.state.id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.orders = res 
			},

			async get_apartments(){
				const res = await axios.get(this.$store.state.url+'api/agent-apartments/'+this.$store.state.id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.apartments = res 
			}
		
		},

		created(){
			this.get_apartments()
			this.get_orders()
		}

	}

</script>