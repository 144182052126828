<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div class="row">

				<div class="col-md-12 mt-3">


					<div>
						
						<div>
						<h3>Settings</h3>
					</div>


					<div class="shadow rounded p-3">


					<form @submit.prevent="change_settings()">
						
						<div class="row">

							<div class="col-md-6 mt-2">
								<label>First name</label>
								<input type="text"  class="form-control" v-model="first_name">
							</div>

							<div class="col-md-6 mt-2">
								<label>Last name</label>
								<input type="text"  class="form-control" v-model="last_name">
							</div>

							<div class="col-md-6 mt-2">
								<label>Email</label>
								<input type="email"  class="form-control" v-model="email">
							</div>


							<div class="col-md-6 mt-2">
								<label>Phone</label>
								<input type="text"  class="form-control" v-model="phone">
							</div>


							<div class="col-md-6 mt-2">
								<label>County</label>
								<input type="text"  class="form-control" v-model="county">
							</div>

							<div class="col-md-6 mt-2">
								<label>Address</label>
								<input type="text"  class="form-control" v-model="address">
							</div>


							<div class="col-md-6 mt-2">
								<label>Password</label>
								<input type="password"  class="form-control" v-model="password">
							</div>

							<div class="col-md-12 mt-3">
							<button class="btn btn-1">Save changes</button>
							</div>
							
						</div>

					</form>				
					
					<div>
						
						
					</div>


					</div>

					</div>
					

				</div>


				
			</div>


		

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	import Swal from 'sweetalert2'
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
			first_name : '',
			last_name : '',
			email : '',
			phone : '',
			county : '',
			address : '',
			password : ''	
			
			}
		},

		methods : {
			async get_details(){
				const res = await axios.get(this.$store.state.url+'api/agent-details/'+this.$store.state.id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				res.forEach((data)=>{
					this.first_name = data.first_name
					this.last_name = data.last_name
					this.email = data.email
					this.phone = data.phone
					this.county = data.county
					this.address = data.address
				})
			},


			async change_settings(){

				if(!this.first_name){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "First name cannot be blank",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}


				if(!this.last_name){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Last name cannot be blank",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}


				if(!this.email){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Email cannot be blank",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}

				if(!this.phone){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Phone cannot be blank",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}

				if(!this.county){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "County cannot be blank",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}


				if(!this.address){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Address cannot be blank",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}

				const res = await axios.post(this.$store.state.url+'api/update/agent-profile',{
					user_id : this.$store.state.id,
					first_name : this.first_name,
					last_name : this.last_name,
					email : this.email,
					phone : this.phone,
					county : this.county,
					address : this.address,
					password : this.password
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				if(res=='success'){
				Swal.fire({
					  position: "top-center",
					  icon: "success",
					  title: "Changes saved successfully",
					  showConfirmButton: false,
					  timer: 1500
					});	
				}else{
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Something went wrong!",
					  showConfirmButton: false,
					  timer: 1500
					});
				}
			
			}
		
		},

		created(){
		this.get_details()	
		}

	}

</script>