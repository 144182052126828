import { createStore } from 'vuex'

export default createStore({
  state: {
    url : 'https://server.safiribykenyans.co.ke/',
    first_name : localStorage.getItem('first_name'),
    last_name : localStorage.getItem('last_name'),
    email : localStorage.getItem('email'),
    id : localStorage.getItem('id'),
    county : localStorage.getItem('county'),
    address : localStorage.getItem('address')
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
