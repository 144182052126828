<template>
	<div class="navcontainer"> 
			<nav class="nav"> 
				<div class="nav-upper-options"> 
					<div class="nav-option"> 
					<router-link class="btn option1" to="/dashboard"><i class="bi bi-speedometer"></i> Dashboard</router-link> 
					</div> 


		

		<div class="nav-option">
	<div class="dropdown">
  <button class="btn option1 dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
     <i class="bi bi-layers"></i> Apartments
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li><router-link to="/new-apartment" class="dropdown-item">Add new</router-link></li>
    <li><router-link to="/my-apartments" class="dropdown-item">View  apartments</router-link></li>

  </ul>
</div>
</div>


	<div class="nav-option"> 
					<router-link class="btn option1" to="/settings"><i class="bi bi-gear"></i> Settings</router-link> 
					</div>



						<div class="nav-option"> 
					<router-link class="btn option1" to="/orders"><i class="bi bi-list"></i> Orders</router-link> 
					</div>


					<div class="nav-option"> 
					<router-link class="btn option1" to="/block-dates"><i class="bi bi-calendar"></i> Block Dates</router-link> 
					</div>







						<div class="nav-option"> 
					<button @click="logout()" class="btn option1"><i class="bi bi-power"></i> Log Out</button> 
					</div> 


					
				</div> 
			</nav> 
		</div>
</template>


<script>
	export default{

		name : 'sidebar',

		methods : {
			logout(){
				this.$router.push('/')
			}
		}

	}

</script>