<template>


<section>
	

	<div class="container">

		<div class="row">

			<div class="col-md-6" style="background-image: url('/assets/images/portrait-interracial-couple-home (1).jpg'); background-size: cover; background-position: center; "></div>

			<div class="col-md-4">
				
				<div style="height:100vh; padding-top: 100px;">

					<div style="padding-bottom: 20px; text-align: center;">
						<img src="/assets/images/new-logo.png" style="height: 70px;">
						<h3><b>Welcome Back!</b></h3>
						<h4>Reset Password</h4>
					</div>
					
					<form @submit.prevent="login()">
					
						<div> 
							<label>Email address <span class="text-danger">*</span> </label>
							<input v-model="email" class="form-control" placeholder="Enter email address" type="text">
						</div>

						<div class="mt-3">
							<button :disabled="disabled" class="btn btn-1 w-100">{{text}} </button>
						</div>


						<div class="mt-3">
							<router-link class="btn p-0" to="/">Have an account? <span class="text-primary">Login</span> </router-link>
						</div>

					</form>

				</div>

			</div>
			
			
		</div>
		
	</div>


</section>


</template>



<script>
	import axios from 'axios'
	import Swal from 'sweetalert2'

	export default{
		name  : 'login',
		data(){
			return{
				email : '',
				disabled : false,
				text : 'Reset password'
			}
		},

		methods : {
			async login(){
				if(!this.email){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Email is required",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}


				this.disabled = true
				this.text = 'Please wait...'


				const res = await axios.post(this.$store.state.url+'api/reset-agent-password',{
					email : this.email
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				Swal.fire({
					  position: "top-center",
					  icon: "success",
					  title: "A new password has been sent to your email",
					  showConfirmButton: true,
					});

				this.disabled = false
				this.text = 'Reset password'
				

			}
		}
	}

</script>